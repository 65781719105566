<script setup lang="ts">
const { t } = useI18n()
const { toggle } = useExpressBooking()
const { icons } = useDesign()

defineOptions({
  name: 'ExpressBookingMobileHeader',
})
</script>

<template>
  <div class="border-neutral-light-1 dark:border-neutral-dark-1 border-b">
    <div class="bg-primary h-1.5 w-full" />

    <div class="text-dark dark:text-light flex h-16 items-center">
      <Icon :name="icons.TICKET_OUTLINE" class="m-4 size-6 fill-current" />

      <div class="flex-grow text-3xl" v-text="t('title')" />

      <Button
        class="m-4"
        :theme="BUTTON_THEME.TERTIARY"
        :icon="icons.CLOSE"
        @click="toggle"
      />
    </div>
  </div>
</template>

<i18n>
de:
  title: "Expressbuchung"
es:
  title: "Reserva express"
</i18n>
